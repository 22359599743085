import { FunctionalComponent } from "preact";
import { useState, useEffect } from "preact/hooks";
import CodeBlock from "./CodeBlock.tsx";
import LogMonitor from "../../islands/LogMonitor.tsx"; // Import LogMonitor directly

const useLogMonitor = false;

interface TryItOutProps {
  selectedServer: { url: string; description: string } | null;
  selectedPath: string | null;
  selectedMethod: string | null;
  selectedParameters: any[];
  selectedResponses: any[];
  requestBody: string;  
  onStatusCodeChange: (statusCode: number) => void; // Expect number here
}

const TryItOut: FunctionalComponent<TryItOutProps> = ({
  selectedServer,
  selectedPath,
  selectedMethod,
  selectedParameters,
  selectedResponses,
  requestBody, 
  onStatusCodeChange,  // Pass status code as number
}) => {
  const [response, setResponse] = useState<string | null>(null);
  const [statusCode, setStatusCode] = useState<number | null>(null);
  const [headers, setHeaders] = useState<Record<string, string> | null>(null);
  const [duration, setDuration] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [forceUpdate, setForceUpdate] = useState<number>(0);

  useEffect(() => {
    setResponse(null);
    setHeaders(null);
    setDuration(null);
    setForceUpdate(forceUpdate + 1);
  }, [selectedPath, selectedMethod]);

  const logMessage = async (origin: string, message: string) => {
    if (useLogMonitor) {
      setTimeout(() => {
        (globalThis as any).logError(origin, message);
      }, 0);
    }
  };

  const handleTryItOut = async () => {
    if (!selectedServer || !selectedMethod || !selectedPath) {
      logMessage("TryItOut", "Bitte stellen Sie sicher, dass alle Felder ausgewählt sind.");
      return alert("Bitte stellen Sie sicher, dass alle Felder ausgewählt sind.");
    }

    setIsLoading(true);
    const startTime = performance.now();

    let updatedPath = selectedPath;
    selectedParameters.forEach((param) => {
      if (param.in === "path" && param.value) {
        updatedPath = updatedPath.replace(`{${param.name}}`, encodeURIComponent(param.value));
      }
    });

    const queryParams = new URLSearchParams();
    selectedParameters.forEach((param) => {
      if (param.in === "query" && param.value !== "(empty)") {
        queryParams.append(param.name, param.value);
      }
    });

    const fullUrl = `${selectedServer.url}${updatedPath}${
      queryParams.toString() ? `?${queryParams.toString()}` : ""
    }`;

    const acceptHeader = selectedResponses.length > 0 ? selectedResponses[0].mediaType : "application/fhir+json";
    const contentTypeHeader = "application/fhir+json";

    logMessage("TryItOut", `Request Body übergeben: ${requestBody ? requestBody : "kein Body übergeben"}`);

    const fetchConfig: RequestInit = {
      method: (selectedMethod || "GET").toUpperCase(), 
      headers: {
        Accept: acceptHeader,
        "Content-Type": contentTypeHeader, 
      },
      credentials: 'include',
    };

    if (["POST", "PUT", "PATCH"].includes((selectedMethod || "").toUpperCase())) {
      if (requestBody) {
        fetchConfig.body = requestBody;

        logMessage("TryItOut", `POST ${updatedPath} HTTP/1.1
    Host: ${selectedServer.url}
    Content-Type: ${contentTypeHeader}
    Accept: ${acceptHeader}

    Request Body: ${requestBody}`);
      } else {
        logMessage("TryItOut", `Warning: Expected request body for method ${selectedMethod} but none was provided.`);
      }
    }

    try {
      const res = await fetch(`/api/RESTCalls?url=${encodeURIComponent(fullUrl)}`, fetchConfig);

      const contentType = res.headers.get("content-type");
      logMessage("TryItOut", `Received response with content-type: ${contentType}`);

      const result = await res.text();
      const endTime = performance.now();
      setDuration(endTime - startTime);

      // Pass status code as number
      setStatusCode(res.status);
      onStatusCodeChange(res.status);  // Ensure the status code is passed as number

      const responseHeaders: Record<string, string> = {};
      res.headers.forEach((value, key) => {
        responseHeaders[key] = value;
      });

      setHeaders(responseHeaders);

      if (contentType && contentType.includes("application/json")) {
        try {
          const jsonResponse = JSON.parse(result);
          setResponse(JSON.stringify(jsonResponse, null, 2)); 
        } catch (error) {
          setResponse(`Invalid JSON format: ${error.message}`);
          logMessage("TryItOut", `Error parsing JSON: ${error.message}`);
        }
      } else {
        setResponse(result);
      }

      setForceUpdate(forceUpdate + 1);
    } catch (error) {
      logMessage("TryItOut", `Fehler: ${error.message}`);
      setResponse(`Fehler: ${error.message}`);
      onStatusCodeChange(500);  // Pass error status code as number
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div class="border p-2 mt-6 bg-slate-100 rounded-md shadow-md mb-6">
      <div class="mb-1 mt-4 flex justify-end">
        <button
          onClick={handleTryItOut}
          class="bg-slate-400 font-bold hover:opacity-80 text-white px-8 py-2 rounded-xl"
          disabled={isLoading}
        >
          {isLoading ? "Fetching..." : "Try it out"}
        </button>
      </div>

      {headers && (
        <>
        <div class="font-semibold m-2" style="text-align: right">
        {duration !== null ? `Response Time: ${duration.toFixed(0)} ms` : "N/A"}
      </div>

          <h5 class="font-semibold">API Response Headers:</h5>
          <CodeBlock key={forceUpdate} code={JSON.stringify(headers, null, 2)} language="json" />
        </>
      )}

      {response && (
        <div class="mt-2">
            <h5 class="font-semibold">API Response Body:</h5>
            <CodeBlock key={forceUpdate} code={response} language="json" />
    
        </div>
      )}
    </div>
  );
};

export default TryItOut;
